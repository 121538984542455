import React from 'react';

import countries from '../../data/countries';
import useTranslation from '../../hooks/useTranslation';

const Content = ({ heading }) => {
  const t = useTranslation();

  const renderCountries = () => (
    <ul className="Rate__list">
      {countries.map((country, key) => (
        <li key={key}>
          <article key={key} className="Rate__article">
            <a
              href={country.email ? `mailto:${country.email}` : undefined}
              onClick={event =>
                country.email ? undefined : event.preventDefault()
              }
            >
              <div>
                {country.logo}
                <h3>{country.label}</h3>
              </div>
            </a>
          </article>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="Rate__content">
      <div className="container">
        <div className="Rate__message">{heading}</div>
        {/*<h2>{t(`pricing.demand`)}</h2>*/}
        {/*<div className="Rate__message">{t(`pricing.contact`)}</div>*/}
        {renderCountries()}
      </div>
    </section>
  );
};

export default Content;

import React from 'react';
import useTranslation from '../../hooks/useTranslation';

import countries from '../../data/countries';

//
// const countries = [
//   {
//     label: `Cameroun`,
//     logo: <Cameroun />,
//   },
// ];

const Now = () => {
  const t = useTranslation();

  const renderCountries = () => (
    <ul className="Rate__list">
      {countries.map((country, key) => (
        <li key={key}>
          <article key={key} className="Rate__article">
            <div>
              {country.logo}
              <h3>{country.label}</h3>
            </div>
          </article>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="Rate__content">
      <div className="container">
        <div className="Rate__message">{t(`pricing.download`)}</div>
        {renderCountries()}
      </div>
    </section>
  );
};

export default Now;

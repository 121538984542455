import React from 'react';

const countries = [
  {
    label: `Belgique`,
  },
];

const Coming = () => {
  const renderCountries = () => (
    <ul className="Rate__list">
      {countries.map((country, key) => (
        <li>
          <article key={key} className="Rate__article">
            <h3>{country.label}</h3>
          </article>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="Rate__content">
      <div className="container">
        <h2>À venir</h2>
        {renderCountries()}
      </div>
    </section>
  );
};

export default Coming;
